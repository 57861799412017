import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";

function PrivateRoute() {
  const user = JSON.parse(localStorage.getItem("userInformation"));

  if (!user) {
    toast.warning("You need to login or signup before placing an order");
    return <Navigate to="/signup" replace />;
  }

  return <Outlet />;
}

export default PrivateRoute;
