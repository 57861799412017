import React, { useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../constants/url";
import { useSelector } from "react-redux";

const SignupScreen = () => {
  const orderDetails = useSelector((state) => state.order.orderDetails);
  console.log(orderDetails, "11");

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
  });

  const [validationErrors, setValidationErrors] = useState({});

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Clear validation error for the current field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: "",
    }));
  };

  const validateForm = () => {
    let errors = {};

    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.phoneNumber) errors.phoneNumber = "Phone number is required";
    if (!formData.password) errors.password = "Password is required";

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSignup = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill in all required fields");
      return;
    }

    axios
      .post(BASE_URL + "/users/signup", formData)
      .then((response) => {
        console.log("Signup response:", response.data);
        localStorage.setItem("userInformation", JSON.stringify(response.data));
        toast.success("User registered successfully!");
        if (orderDetails.length === 0) {
          navigate("/");
        } else {
          navigate("/checkout");
        }
      })
      .catch((error) => {
        console.log(error, "42");
        console.error("Signup failed:", error);
        toast.error("Something went wrong");
      });
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      if (credentialResponse.credential) {
        const response = await axios.post(`${BASE_URL}/users/oneclick-signup`, {
          accessToken: credentialResponse.credential,
        });

        if (response.status === 201) {
          console.log("Google signup response:", response.data);
          localStorage.setItem(
            "userInformation",
            JSON.stringify(response.data)
          );
          toast.success("User registered successfully!");
          if (orderDetails.length === 0) {
            navigate("/");
          } else {
            navigate("/checkout");
          }
        } else {
          toast.error(
            response.data.message || "Signup failed. Please try again."
          );
        }
      } else {
        toast.error("Credential response is missing.");
      }
    } catch (error) {
      console.error("Google signup failed:", error);

      if (error.response) {
        const errorMessage =
          error.response.data.message || "An error occurred during signup.";
        toast.error(errorMessage);
      } else if (error.request) {
        toast.error("No response from the server. Please try again later.");
      } else {
        toast.error("Google sign-in failed. Please try again later.");
      }
    }
  };

  const handleGoogleFailure = () => {
    console.error("Google sign-in failed");
    toast.error("Google sign-in failed. Please try again.");
  };

  return (
    <GoogleOAuthProvider clientId="336957385742-3qe4j6a0e725pqni3fdmu3fg0oehrsjb.apps.googleusercontent.com">
      <div className="font-poppins">
        <div className="w-full bg-[#E9C876] py-8">
          <div className="container mx-auto text-center">
            <nav aria-label="breadcrumb">
              <ol className="inline-flex items-center space-x-2">
                <li>
                  <a href="/" className="text-gray-700 hover:text-gray-900">
                    Home
                  </a>
                </li>
                <li>
                  <span className="text-gray-700"> &gt; </span>
                </li>
                <li className="text-[#5C2020] font-bold">Signup</li>
              </ol>
            </nav>
          </div>
        </div>
        {/* Signup form */}
        <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8 bg-[#F5F5F5] p-12 rounded-2xl">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900">Signup</h2>
            </div>
            <form onSubmit={handleSignup} className="mt-8 space-y-6">
              <div className="rounded-md shadow-sm space-y-4">
                {/* Name */}
                <div className="mb-4">
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter Full Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className={`appearance-none rounded-lg relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#E9C876] focus:border-[#E9C876] focus:z-10 sm:text-sm mb-4`}
                  />
                  {validationErrors.name && (
                    <p className="text-[red] text-sm">
                      {validationErrors.name}
                    </p>
                  )}
                </div>
                {/* Email Input */}
                <div className="mb-4">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className={`appearance-none rounded-lg relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#E9C876] focus:border-[#E9C876] focus:z-10 sm:text-sm mb-4`}
                  />
                  {validationErrors.email && (
                    <p className="text-[red] text-sm">
                      {validationErrors.email}
                    </p>
                  )}
                </div>
                {/* Phone Number */}
                <div className="mb-4">
                  <input
                    type="number"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    className={`appearance-none rounded-lg relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#E9C876] focus:border-[#E9C876] focus:z-10 sm:text-sm mb-4`}
                  />
                  {validationErrors.phoneNumber && (
                    <p className="text-[red] text-sm">
                      {validationErrors.phoneNumber}
                    </p>
                  )}
                </div>
                {/* Password Input */}
                <div className="mb-4">
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className={`appearance-none rounded-lg relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#E9C876] focus:border-[#E9C876] focus:z-10 sm:text-sm mb-4`}
                  />
                  {validationErrors.password && (
                    <p className="text-[red] text-sm">
                      {validationErrors.password}
                    </p>
                  )}
                </div>
              </div>
              {/* Create Account Button */}
              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-[#E9C876] hover:bg-[#d1a963] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#E9C876]"
                >
                  Create Account
                </button>
              </div>
              {/* Login Prompt */}

              <style>
                {`
          @keyframes shake {
            0% { transform: translateX(0); }
            25% { transform: translateX(-10px); }
            50% { transform: translateX(10px); }
            75% { transform: translateX(-10px); }
            100% { transform: translateX(0); }
          }

          .shake-animation {
            animation: shake 1s ease-in-out 0s 1;
            animation-iteration-count: infinite;
            animation-delay: 0s, 4s; /* Delay before the next shake starts */
            animation-duration: 1s, 3s; /* Shake for 1s, pause for 3s */
          }
        `}
              </style>

              {/* Signup Prompt */}
              <div className="text-center">
                <p className="text-lg font-bold text-gray-600 shake-animation">
                  Already Have An Account?{" "}
                  <a
                    href="/login"
                    className="font-medium text-[#5C202] hover:text-[#E9C876]"
                  >
                    Login Here
                  </a>
                </p>
              </div>

              <p className="text-center"> OR</p>
              {/* Signup with Google Button */}
              <div className="flex justify-center">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleFailure}
                />
              </div>
            </form>
          </div>
        </div>
        <Toaster />
      </div>
    </GoogleOAuthProvider>
  );
};

export default SignupScreen;
