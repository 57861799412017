import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../store/slices/productSlice";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../store/slices/wishlistSlice";
import { FaRegHeart, FaHeart } from "react-icons/fa";

const ProductCard = ({
  image,
  name,
  price,
  discountPrice,
  discountPercentage,
  category,
  id,
}) => {
  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.wishlist.wishlist);
  const isInWishlist = wishlist.some((item) => item._id === id);

  const handleAddToCart = (id) => {
    dispatch(addToCart(id));
  };

  const handleToggleWishlist = (e) => {
    e.preventDefault();
    if (isInWishlist) {
      dispatch(removeFromWishlist(id));
    } else {
      const productToAdd = {
        _id: id,
        image,
        name,
        price,
        discountPrice,
        discountPercentage,
        category,
      };
      dispatch(addToWishlist(productToAdd));
    }
  };

  return (
    <div className="p-2 sm:p-4 font-poppins">
      <div className="bg-white rounded-lg overflow-hidden relative">
        <Link to={`/product/${id}`}>
          <div className="w-full h-48 sm:h-64 lg:h-[600px] flex items-center justify-center bg-gray-100 relative">
            <img
              src={image}
              alt={name}
              className="w-full h-full object-cover rounded-lg"
            />
            <button
              className="absolute top-4 right-4 text-2xl text-gray-700"
              onClick={(e) => handleToggleWishlist(e)}
            >
              {isInWishlist ? <FaHeart color="red" /> : <FaRegHeart />}
            </button>
          </div>
        </Link>
        <div className="p-4">
          <p className="text-sm text-[#5C2028] font-medium text-left mb-2">
            {category}
          </p>
          <h3
            className="text-[12px] sm:text-xl font-semibold mb-2"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "25ch",
            }}
          >
            {name}
          </h3>
          <div className="flex flex-col sm:flex-row sm:items-center gap-2 mb-2">
            <div className="flex items-center gap-2">
              <span className="text-[22px] sm:text-2xl font-bold text-red-600">
                ₹{discountPrice}
              </span>
              <span className="text-[16px] sm:text-xl text-[#969595] line-through">
                ₹{price}
              </span>
            </div>
            <span className="text-[12px] text-left sm:text-base text-[#5C2028] font-medium">
              ({discountPercentage}% OFF)
            </span>
          </div>
          <button
            className="w-full py-2 bg-[#E9C876] text-[black] text-[14px] lg:text-[16px] font-bold rounded-lg hover:bg-[#d1a963] transition duration-200"
            onClick={() => handleAddToCart(id)}
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
