import React, { useState } from "react";

import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import { BASE_URL } from "../constants/url";

const AdminLoginScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(BASE_URL + "/users/admin", {
        email,
        password,
      });

      if (response.status === 201) {
        const { token } = response.data; // Extract token
        localStorage.setItem("authToken", token); // Store token in localStorage
        toast.success("Login successful!");
        navigate("/admin/dashboard");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error(error.response?.data?.message || "Something went wrong!");
    }
  };

  return (
    <div className="font-poppins">
      <div className="w-full bg-[#E9C876] py-8">{/* Breadcrumb */}</div>
      {/* Login form */}
      <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full bg-[#F5F5F5] px-12 py-12 pt-0 rounded-2xl">
          <div className="flex-shrink-0"></div>
          <div className="text-center">
            <img
              src="./assets/logo/logo4-edited.png"
              alt="Logo"
              className="lg:h-40 h-24 py-2 mx-auto mb-4"
            />

            <h2 className="text-2xl font-bold text-gray-900">Admin Login</h2>
          </div>
          <form onSubmit={handleLogin} className="mt-8 space-y-6">
            <div className="rounded-md shadow-sm space-y-4">
              {/* Email Input */}
              <div className="mb-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#E9C876] focus:border-[#E9C876] focus:z-10 sm:text-sm"
                />
              </div>
              {/* Password Input */}
              <div>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#E9C876] focus:border-[#E9C876] focus:z-10 sm:text-sm"
                />
              </div>
            </div>

            {/* Forgot Password */}
            <div className="text-left">
              <a
                href="#"
                className="font-medium text-[#5C202] hover:text-[#E9C876]"
              >
                Forgot password?
              </a>
            </div>

            {/* Login Button */}
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-[#E9C876] hover:bg-[#d1a963] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#E9C876]"
              >
                Admin Login
              </button>
            </div>
          </form>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default AdminLoginScreen;
