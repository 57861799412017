import React from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ setActiveSection, activeSection, cancelRequestCount }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the local storage or any other user-specific data
    localStorage.removeItem("authToken");

    // Navigate to the login screen
    navigate("/admin");
  };

  return (
    <div
      className="fixed w-64 bg-gray-800 text-white p-4 h-full"
      style={{ backgroundColor: "#fff7e4", zIndex: "2" }}
    >
      <div className="flex-shrink-0">
        <a>
          <img
            src="../assets/logo/logo4-edited.png"
            alt="Logo"
            className="h-full w-36 md:h-36 md:w-52 "
          />
        </a>
      </div>

      <h1 className="text-2xl font-bold mb-4">Admin Dashboard</h1>
      <ul>
        <li
          className={`p-2 cursor-pointer ${
            activeSection === "dashboard" && "bg-[#5f202b] text-[white]"
          }`}
          onClick={() => setActiveSection("dashboard")}
        >
          Dashboard
        </li>
        <li
          className={`p-2 cursor-pointer ${
            activeSection === "products" && "bg-[#5f202b] text-[white]"
          }`}
          onClick={() => setActiveSection("products")}
        >
          Products
        </li>
        <li
          className={`p-2 cursor-pointer ${
            activeSection === "orders" && "bg-[#5f202b] text-[white]"
          }`}
          onClick={() => setActiveSection("orders")}
        >
          Orders
        </li>
        <li
          className={`p-2 cursor-pointer ${
            activeSection === "users" && "bg-[#5f202b] text-[white]"
          }`}
          onClick={() => setActiveSection("users")}
        >
          Users
        </li>
        <li
          className={`p-2 cursor-pointer ${
            activeSection === "banners" && "bg-[#5f202b] text-[white]"
          }`}
          onClick={() => setActiveSection("banners")}
        >
          Banners
        </li>
        <li
          className={`p-2 cursor-pointer ${
            activeSection === "bulkorder" && "bg-[#5f202b] text-[white]"
          }`}
          onClick={() => setActiveSection("bulkorder")}
        >
          Bulk Order
        </li>
        <li
          className={`p-2 cursor-pointer ${
            activeSection === "contact" && "bg-[#5f202b] text-[white]"
          }`}
          onClick={() => setActiveSection("contact")}
        >
          Contact
        </li>
        <li
          className={`p-2 cursor-pointer flex items-center ${
            activeSection === "orderCancelRequests" &&
            "bg-[#5f202b] text-[white]"
          }`}
          onClick={() => setActiveSection("orderCancelRequests")}
        >
          Order Cancel Requests
          {cancelRequestCount > 0 && (
            <span className="bg-[red] text-[white] ml-2 px-2 py-1 rounded-full text-sm">
              {cancelRequestCount}
            </span>
          )}
        </li>
        <button
          className="p-2 bg-[red] text-[white] py-2 px-4 rounded-lg hover:bg-red-600 my-4"
          onClick={handleLogout}
        >
          Logout
        </button>
      </ul>

      {/* Logout Button */}
    </div>
  );
};

export default Sidebar;
