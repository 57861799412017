import React from "react";
import { useState } from "react";

import { FaBars, FaTimes, FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [mensDropdownOpen, setMensDropdownOpen] = useState(false);
  const [womensDropdownOpen, setWomensDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleMensDropdown = () => {
    setMensDropdownOpen(!mensDropdownOpen);
  };

  const toggleWomensDropdown = () => {
    setWomensDropdownOpen(!womensDropdownOpen);
  };
  return (
    <div className="font-poppins">
      {/* Desktop Navbar - hidden on small screens */}
      <nav className="bg-brown p-4  hidden md:block font-bold">
        <div className="flex items-center justify-between max-w-fit mx-auto">
          <div
            className="flex items-center space-x-24"
            style={{ fontSize: "18px" }}
          >
            {" "}
            {/* Adjust spacing with space-x-24 */}
            <div className="relative">
              <button
                className="flex items-center space-x-1 focus:outline-none"
                onClick={toggleMensDropdown}
              >
                <span>Mens</span>
                {mensDropdownOpen ? <FaAngleUp /> : <FaAngleDown />}
              </button>
              {mensDropdownOpen && (
                <ul
                  className="absolute left-0 mt-2 w-40  rounded-md shadow-lg z-10"
                  style={{ backgroundColor: "white" }}
                >
                  <a href="/men-chains">
                    <li className="px-4 py-2 hover:bg-gray-600 cursor-pointer">
                      Chains
                    </li>
                  </a>
                  <a href="/men-bracelets">
                    <li className="px-4 py-2 hover:bg-gray-600 cursor-pointer">
                      Bracelets
                    </li>
                  </a>
                </ul>
              )}
            </div>
            <div className="relative">
              <button
                className="flex items-center space-x-1 focus:outline-none"
                onClick={toggleWomensDropdown}
              >
                <span>Womens</span>
                {womensDropdownOpen ? <FaAngleUp /> : <FaAngleDown />}
              </button>
              {womensDropdownOpen && (
                <ul
                  className="absolute left-0 mt-2 w-40 bg-gray-700 rounded-md shadow-lg z-10 "
                  style={{ backgroundColor: "white" }}
                >
                  <a href="/rings">
                    <li className="px-4 py-2 hover:bg-gray-600 cursor-pointer">
                      Rings
                    </li>
                  </a>
                  <a href="/anklets">
                    <li className="px-4 py-2 hover:bg-gray-600 cursor-pointer">
                      Anklets
                    </li>
                  </a>
                  <a href="bracelets">
                    <li className="px-4 py-2 hover:bg-gray-600 cursor-pointer">
                      Bracelets
                    </li>
                  </a>
                </ul>
              )}
            </div>
            <a href="/bracelets">
              <div className="hover:text-gray-400 cursor-pointer">
                Bracelets
              </div>
            </a>
            <a href="/earrings">
              <div className="hover:text-gray-400 cursor-pointer">Earrings</div>
            </a>
            <a href="/anklets">
              <div className="hover:text-gray-400 cursor-pointer">Anklets</div>
            </a>
            <a href="/rings">
              <div className="hover:text-gray-400 cursor-pointer">Rings</div>
            </a>
            <a href="/necklace">
              <div className="hover:text-gray-400 cursor-pointer">Necklace</div>
            </a>
            <Link to="/bulkorder">
              <div
                className="hover:text-gray-400 cursor-pointer p-2 px-5 rounded-lg text-white"
                style={{ backgroundColor: "#5C2028", color: "white" }}
              >
                Bulk Order
              </div>
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
