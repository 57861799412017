import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import Spinner from "../components/Spinner";
import { BASE_URL } from "../constants/url";
import Collapse from "react-collapse";
import Modal from "react-modal";

const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  console.log(selectedImage, "20");

  // Define fetchOrders function outside of useEffect to use it elsewhere
  const fetchOrders = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/orders/myorders`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("userInformation")).token
          }`,
        },
      });

      if (response.data.data.length) {
        const sortedOrders = response.data.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setOrders(sortedOrders);
      } else {
        toast.error("No orders found.");
      }
      setLoading(false);
    } catch (error) {
      toast.error("Failed to fetch orders.");
      setLoading(false);
    }
  }, []); // Use useCallback to memoize the function and avoid re-creating it

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]); // Add fetchOrders as a dependency

  const handleCancelRequest = (orderId) => {
    setSelectedOrderId(orderId);
    setSelectedReason(""); // Reset selected reason
    setOtherReason(""); // Reset other reason
    setIsModalOpen(true);
  };

  const submitCancelRequest = async () => {
    // Validation: Check if a reason is selected
    if (!selectedReason) {
      toast.error("Please select a reason for cancellation.");
      return;
    }

    // Validation: Check if 'otherReason' is provided
    if (!otherReason) {
      toast.error("Please specify additional details.");
      return;
    }

    // Validation: Check if an image is selected
    if (!selectedImage) {
      toast.error("Please upload an image.");
      return;
    }

    const formData = new FormData();
    formData.append("reason", selectedReason);
    formData.append("otherReason", otherReason);
    formData.append("image", selectedImage);

    try {
      const response = await axios.post(
        `${BASE_URL}/orders/request-cancel/${selectedOrderId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("userInformation")).token
            }`,
            "Content-Type": "multipart/form-data", // Important for file upload
          },
        }
      );

      if (response.status === 200) {
        toast.success("Cancel request sent successfully!");

        // Fetch orders again after a successful cancel request to update the order list
        fetchOrders();
        setIsModalOpen(false); // Close the modal after successful cancel request
      } else {
        toast.error("Failed to send cancel request.");
      }
    } catch (error) {
      // Handle error from backend
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message); // Show the backend error message
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }

      console.error("Error:", error); // Log the error for debugging
    }
  };

  const toggleExpandOrder = (orderId) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };

  return (
    <div className="w-full lg:w-full xl:w-full bg-white p-2 lg:p-6 rounded-lg shadow-md mx-auto my-12">
      <h2 className="text-3xl font-bold mb-4">My Orders</h2>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b text-left">Order ID</th>
            <th className="py-2 px-4 border-b text-left hidden lg:table-cell">
              Order Date
            </th>
            <th className="py-2 px-4 border-b text-left hidden lg:table-cell">
              Payment Status
            </th>
            <th className="py-2 px-4 border-b text-left hidden lg:table-cell">
              Fulfillment Status
            </th>
            <th className="py-2 px-4 border-b text-left hidden lg:table-cell">
              Total
            </th>
            <th className="py-2 px-4 border-b text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="6" className="text-center py-4">
                <Spinner />
              </td>
            </tr>
          ) : (
            orders.map((order) => (
              <React.Fragment key={order._id}>
                <tr>
                  <td className="py-2 px-4 border-b text-left">
                    {order._id}

                    <p
                      className="text-blue-500 cursor-pointer underline text-sm hover:underline-offset-4 underline"
                      onClick={() => toggleExpandOrder(order._id)}
                    >
                      View Details
                    </p>
                  </td>
                  <td className="py-2 px-4 border-b text-left hidden lg:table-cell">
                    {moment(order.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                  </td>
                  <td className="py-2 px-4 border-b text-left hidden lg:table-cell">
                    {order.paymentStatus}
                  </td>
                  <td className="py-2 px-4 border-b text-left hidden lg:table-cell">
                    {order.fulfillmentStatus}
                  </td>
                  <td className="py-2 px-4 border-b text-left hidden lg:table-cell">
                    ₹{order.total}
                  </td>
                  <td className="py-2 px-4 border-b text-left">
                    {/* {order.status !== "cancelled" && (
                      <button
                        className="text-[white] px-2 py-1 rounded-md bg-[green] hover:bg-[#00b900] mr-2 text-sm"
                        disabled={order.status === "cancelled"}
                      >
                        Track Order
                      </button>
                    )} */}

                    {order.status === "cancelled" ? (
                      <span className="text-red-500">Order Cancelled</span>
                    ) : order.status === "pending" ? (
                      <span className="text-yellow-500">Request Sent</span>
                    ) : (
                      <button
                        onClick={() => handleCancelRequest(order._id)}
                        className="text-[black] px-3 py-1 rounded-md bg-[#F5F5F5] hover:bg-[#d8d7d7]"
                      >
                        Cancel Request
                      </button>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="6">
                    <Collapse isOpened={expandedOrderId === order._id}>
                      <div className="p-4 border-t border-gray-300">
                        {/* Display the hidden details on mobile when expanded */}
                        <div className="block lg:hidden mb-4">
                          <p>
                            <strong>Order Date:</strong>{" "}
                            {moment(order.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </p>
                          <p>
                            <strong>Payment Status:</strong>{" "}
                            {order.paymentStatus}
                          </p>
                          <p>
                            <strong>Fulfillment Status:</strong>{" "}
                            {order.fulfillmentStatus}
                          </p>
                          <p>
                            <strong>Total:</strong> ₹{order.total}
                          </p>
                        </div>
                        {order.products.map((product) => (
                          <div key={product.productId} className="mb-4">
                            <div className="flex items-center mb-2">
                              <img
                                src={product.productImage}
                                alt={product.productName}
                                className="w-24 h-24 object-cover mr-4"
                              />
                              <div>
                                <p className="font-bold">
                                  {product.productName}
                                </p>
                                <p>Price: ₹{product.price}</p>
                                <p>Qty: {product.quantity}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Collapse>
                  </td>
                </tr>
              </React.Fragment>
            ))
          )}
        </tbody>
      </table>

      {/* Modal for Cancel Request */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        ariaHideApp={false}
        className="fixed inset-0 flex items-center justify-center p-4 bg-gray-900 bg-opacity-50"
        style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
      >
        <div className="bg-[white] p-6 rounded-lg shadow-md w-full max-w-md">
          <h2 className="text-xl font-bold mb-4">Cancel Order Request</h2>

          {/* Add Reason Selection */}
          <div className="mb-4">
            <label
              htmlFor="reason"
              className="block text-sm font-medium text-gray-700"
            >
              Select a Reason
            </label>
            <select
              id="reason"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={selectedReason}
              onChange={(e) => setSelectedReason(e.target.value)}
            >
              <option value="">Select a reason...</option>
              <option value="Found a better price">Found a better price</option>
              <option value="Item not needed">Item not needed</option>
              <option value="Ordered by mistake">Ordered by mistake</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {/* Show textarea for additional details (for all reasons) */}
          <div className="mb-4">
            <label
              htmlFor="otherReason"
              className="block text-sm font-medium text-gray-700"
            >
              Additional Details
            </label>
            <textarea
              id="otherReason"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={otherReason}
              onChange={(e) => setOtherReason(e.target.value)}
              rows={4}
            />
          </div>

          {/* Image upload for all reasons */}
          <div className="mb-4">
            <label
              htmlFor="imageUpload"
              className="block text-sm font-medium text-gray-700"
            >
              Upload an Image
            </label>
            <input
              type="file"
              id="imageUpload"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setSelectedImage(e.target.files[0]);
                } else {
                  setSelectedImage(null); // Clear the state if no file is selected
                }
              }}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div className="flex justify-end">
            <button
              onClick={submitCancelRequest}
              className="text-[white] px-4 py-2 bg-[green] rounded-md hover:bg-green-600"
            >
              Submit Request
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              className="ml-2 text-[white] px-4 py-2 bg-[red] rounded-md hover:bg-red-600"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MyOrders;
