import React, { useState, useEffect } from "react";
import axios from "axios";
import TableComponent from "./TableComponent";

import { BASE_URL } from "../../constants/url";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
import {BannerModal} from "./BannerModal "

const Banners = () => {
  const [banners, setBanners] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/home/all-banner`)
      .then((response) => {
        if (response.data.status) {
          // Sort banners by date (assuming 'createdAt' is the property holding date info)
          const sortedBanners = response.data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setBanners(sortedBanners);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const columns = [
    { Header: "ID", accessor: "_id" },
    { Header: "Order", accessor: "order" },
    {
      Header: "Image",
      accessor: "imageLink",
      Cell: ({ cell: { value } }) => (
        <img src={value} alt="Banner" className="h-20 w-full object-cover" />
      ),
    },
    { Header: "Description", accessor: "description" },
    { Header: "Category", accessor: "category" },
    {
      Header: "Date Added",
      accessor: "createdAt",
      Cell: ({ cell: { value } }) => new Date(value).toLocaleString(), // Format date and time
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div>
          <button
            className="bg-[green] text-[white] px-2 py-1 mr-2 rounded"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-[red] text-[white] px-2 py-1 rounded"
            onClick={() => handleDelete(row.original._id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleEdit = (banner) => {
    setSelectedBanner(banner);
    setModalOpen(true);
  };

  const handleDelete = (id) => {
    const verify = window.confirm("Are you sure?");
    if (verify) {
      axios
        .delete(`${BASE_URL}/home/delete-banner/${id}`)
        .then(() => {
          setBanners(banners.filter((banner) => banner._id !== id));
          toast.success("Banner is deleted successfully");
        })
        .catch((error) => console.error(error));
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedBanner(null);
  };

  const handleBannerUpdated = () => {
    fetchBanners();
  };

  return (
    <div className="ml-[280px]">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl">Banners</h1>
        {/* <div>
          <button
            className="bg-[#e7e7e7] text-white px-4 py-2 rounded"
            onClick={() => setModalOpen(true)}
          >
            Add Banners
          </button>
        </div> */}
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <TableComponent columns={columns} data={banners} />
      )}
      {modalOpen && (
        <BannerModal
          isOpen={modalOpen}
          onRequestClose={handleModalClose}
          banner={selectedBanner}
          onBannerUpdated={handleBannerUpdated}
        />
      )}
    </div>
  );
};

export default Banners;
