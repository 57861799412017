import React, { useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "react-modal";

import { BASE_URL } from "../constants/url";
// Set the app element for react-modal
Modal.setAppElement("#root");

const LoginScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpDisabled, setOtpDisabled] = useState(true);
  const [otpId, setOtpId] = useState(null);

  const orderDetails = useSelector((state) => state.order.orderDetails);
  console.log(orderDetails, "24");

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(BASE_URL + "/users/login", {
        email,
        password,
      });

      if (response.status === 201) {
        localStorage.setItem("userInformation", JSON.stringify(response.data));
        toast.success("Login successful!");
        if (orderDetails.length === 0) {
          navigate("/");
        } else {
          navigate("/checkout");
        }
      } else {
        toast.error();
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    if (credentialResponse.credential) {
      try {
        const response = await axios.post(`${BASE_URL}/users/login-auth`, {
          token: credentialResponse.credential,
        });

        // Log the response for debugging
        console.log("Google sign-in response:", response);

        // Check for successful login status
        if (response.status === 201) {
          // Typically, 200 indicates success
          localStorage.setItem(
            "userInformation",
            JSON.stringify(response.data)
          );
          toast.success("Google sign-in successful!");
          if (orderDetails.length === 0) {
            navigate("/");
          } else {
            navigate("/checkout");
          }
        } else {
          toast.error("Google sign-in failed.");
        }
      } catch (error) {
        // Log error for debugging
        console.error("Google sign-in error:", error);
        toast.error(error.response.data.message);
      }
    } else {
      toast.error("Google sign-in failed. No credential received.");
    }
  };

  const handleGoogleFailure = () => {
    toast.error("Google sign-in failed. Please try again.");
  };

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(BASE_URL + "/users/forgot-password", {
        email: forgotEmail,
      });

      if (response.status === 201) {
        toast.success("OTP sent to your email!");
        console.log(response.data, "81");
        setOtpDisabled(false);
        setOtpId(response.data._id);
        setOtp(response.data.otp);
      } else {
        toast.error("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.log(error, "85");
      toast.error(`${error.response.data.message}`);
    }
  };

  const handleOtpVerify = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        BASE_URL + "/users/forgot-pass-otp-verify",
        {
          userId: otpId, // Use the saved ID
          otp,
        }
      );

      if (response.status === 201) {
        toast.success("OTP verified successfully!");
        const queryParams = new URLSearchParams({
          userId: otpId,
          token: `${response.data.changePassToken}`,
        }).toString();
        navigate(`/create-new-password?${queryParams}`);
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      console.log(error, "OTP verification error");
      toast.error(`${error.response.data.message}`);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <GoogleOAuthProvider clientId="336957385742-3qe4j6a0e725pqni3fdmu3fg0oehrsjb.apps.googleusercontent.com">
      <div className="font-poppins">
        <div className="w-full bg-[#E9C876] py-8">{/* Breadcrumb */}</div>
        {/* Login form */}
        <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8 bg-[#F5F5F5] p-12 rounded-2xl">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900">Login</h2>
            </div>
            <form onSubmit={handleLogin} className="mt-8 space-y-6">
              <div className="rounded-md shadow-sm space-y-4">
                {/* Email Input */}
                <div className="mb-4">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#E9C876] focus:border-[#E9C876] focus:z-10 sm:text-sm"
                  />
                </div>
                {/* Password Input */}
                <div>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#E9C876] focus:border-[#E9C876] focus:z-10 sm:text-sm"
                  />
                </div>
              </div>

              {/* Forgot Password */}
              <div className="text-left">
                <button
                  type="button"
                  onClick={openModal}
                  className="font-medium text-[#5C202] hover:text-[#E9C876]"
                >
                  Forgot password?
                </button>
              </div>

              {/* Login Button */}
              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-[#E9C876] hover:bg-[#d1a963] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#E9C876]"
                >
                  Login
                </button>
              </div>

              <style>
                {`
          @keyframes shake {
            0% { transform: translateX(0); }
            25% { transform: translateX(-10px); }
            50% { transform: translateX(10px); }
            75% { transform: translateX(-10px); }
            100% { transform: translateX(0); }
          }

          .shake-animation {
            animation: shake 1s ease-in-out 3s forwards;
            animation-iteration-count: infinite;
            animation-delay: 0s, 4s; /* Delay before the next shake starts */
            animation-duration: 1s, 3s;
          }
        `}
              </style>

              {/* Signup Prompt */}
              <div className="text-center">
                <p className="text-lg font-bold text-gray-600 shake-animation">
                  Don’t have an account?{" "}
                  <a
                    href="/signup"
                    className="font-medium text-[#5C202] hover:text-[#E9C876]"
                  >
                    Signup Here
                  </a>
                </p>
              </div>

              <p className="text-center"> OR</p>

              {/* Signup with Google Button */}
              <div className="flex justify-center">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleFailure}
                  className="mx-auto"
                />
              </div>
            </form>
          </div>
        </div>
        <Toaster />
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Forgot Password"
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              position: "absolute",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              transform: "translate(-50%, -50%)",
              width: "90%",
              maxWidth: "500px",
              background: "white",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              position: "relative",
            },
          }}
        >
          <button
            onClick={closeModal}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              border: "none",
              fontSize: "20px",
              cursor: "pointer",
              color: "#5C2020", // Adjust color as needed
            }}
          >
            &times;
          </button>
          <div>
            <h2 className="text-lg font-semibold mb-4">Forgot Password</h2>
            <form onSubmit={handleForgotPassword} className="space-y-4">
              {/* Email Input */}
              <div>
                <input
                  type="email"
                  value={forgotEmail}
                  onChange={(e) => setForgotEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#E9C876] focus:border-[#E9C876] focus:z-10 sm:text-sm"
                />
              </div>
              {/* Submit Button */}
              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-[#E9C876] hover:bg-[#d1a963] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#E9C876]"
                >
                  Send OTP
                </button>
              </div>
            </form>
            <form onSubmit={handleOtpVerify} className="space-y-4 mt-4">
              <div>
                <input
                  type="text"
                  // value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter OTP"
                  className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#E9C876] focus:border-[#E9C876] focus:z-10 sm:text-sm"
                  disabled={otpDisabled}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-[#E9C876] hover:bg-[#d1a963] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#E9C876]"
                  disabled={otpDisabled}
                >
                  Verify OTP
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </GoogleOAuthProvider>
  );
};

export default LoginScreen;
