import React from "react";

const FooterBottom = () => {
	return (
		<div className="bg-[#E9C876] py-4 w-full font-poppins">
			{/* Red background for the Footer Bottom */}
			<div className="flex flex-col md:flex-row justify-between items-center space-y-2 md:space-y-0 px-12">
				<p className="text-center md:text-left">
					&copy; 2024 GustyGlow. All rights reserved.
				</p>
			
				<p className="text-center md:text-right">
					Creatively designed by{" "}
					<a
						href="https://syntheticotech.com/"
						target="_blank"
						rel="noopener noreferrer"
						className="hover:underline"
					>
						Synthetico
					</a>
				</p>
			</div>
		</div>
	);
};

export default FooterBottom;
