import React, { useState, useEffect, lazy, Suspense } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import CSS
import { BASE_URL } from "../../constants/url";
import Spinner from "../Spinner";

// Lazy load the Carousel component
const Carousel = lazy(() =>
  import("react-responsive-carousel").then((module) => ({
    default: module.Carousel,
  }))
);

const HomeBannerNew = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Fetch banner data from the API
    const fetchBanners = async () => {
      try {
        const response = await fetch(BASE_URL + "/home/home-data");
        const data = await response.json();

        if (data && data.data && data.data.banner) {
          const sortedBanners = data.data.banner.sort(
            (a, b) => a.order - b.order
          );
          setBanners(sortedBanners);
        } else {
          console.error("Banner data structure is not as expected.");
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };
    fetchBanners();
  }, []);

  const styles = {
    homeBanner: {
      position: "relative",
      width: "100%",
      height: "100%", // Full viewport height
      overflow: "hidden",
    },
    bannerSlide: {
      position: "relative",
      height: "100%", // Full height of container
    },
    bannerImage: {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },
    // bannerButton: {
    //   color: "white",
    //   padding: "0.5rem 1rem",
    //   borderRadius: "0.5rem",
    //   textDecoration: "none",
    //   display: "inline-block",
    //   textAlign: "center",
    // },
    // bannerOverlay: {
    //   position: "absolute",
    //   bottom: "20px",
    //   left: "50%",
    //   transform: "translateX(-50%)",
    //   display: "flex",
    //   justifyContent: "center",
    //   width: "100%",
    // },
  };

  return (
    <div style={styles.homeBanner}>
      <Suspense
        fallback={
          <div>
            <Spinner />
          </div>
        }
      >
        <Carousel
          showArrows={true}
          autoPlay
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          interval={3000}
          swipeable
          emulateTouch
          dynamicHeight={false}
          useKeyboardArrows
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <div
                onClick={onClickHandler}
                style={{ ...arrowStyle, left: "10px" }}
              >
                &#x2190;
              </div>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <div
                onClick={onClickHandler}
                style={{ ...arrowStyle, right: "10px" }}
              >
                &#x2192;
              </div>
            )
          }
        >
          {banners.map((banner, index) => (
            <a
              key={index}
              href={
                index === 0
                  ? "/necklace"
                  : index === 1
                  ? "/bracelets"
                  : "/rings"
              }
              style={{
                ...styles.bannerSlide,
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <div style={styles.bannerSlide}>
                <img
                  src={banner.imageLink}
                  alt={banner.description}
                  style={styles.bannerImage}
                  loading="lazy" // Lazy load images
                />
                <div style={styles.bannerOverlay}>
                  <span
                    style={{
                      ...styles.bannerButton,
                      ...getButtonStyle(isMobile, index),
                    }}
                  >
                    {index === 0
                      ? "Shop Necklaces"
                      : index === 1
                      ? "Shop Bracelets"
                      : "Shop Rings"}
                  </span>
                </div>
              </div>
            </a>
          ))}
        </Carousel>
      </Suspense>
    </div>
  );
};

const getButtonStyle = (isMobile, index) => {
  if (index === 0) {
    return isMobile
      ? {
          marginBottom: "0px",
          marginLeft: "-200px",
          width: "100px",
          backgroundColor: "#c0ab8c",
          fontSize: "12px",
          color: "black",
          fontWeight: "bold",
        }
      : {
          marginBottom: "170px",
          marginLeft: "-1050px",
          width: "150px",
          backgroundColor: "#c0ab8c",
          color: "black",
          fontWeight: "bold",
        };
  } else if (index === 1) {
    return isMobile
      ? {
          marginBottom: "0px",
          width: "120px",
          fontSize: "12px",
          color: "black",
          fontWeight: "bold",
          backgroundColor: "#b28352",
        }
      : {
          marginBottom: "180px",
          width: "150px",
          backgroundColor: "#b28352",
          color: "black",
          fontWeight: "bold",
        };
  } else {
    return isMobile
      ? {
          marginBottom: "0px",
          marginLeft: "-170px",
          width: "108px",
          backgroundColor: "#a47150",
          fontSize: "12px",
          color: "black",
          fontWeight: "bold",
        }
      : {
          marginBottom: "160px",
          marginLeft: "-950px",
          width: "150px",
          backgroundColor: "#a47150",
          color: "black",
          fontWeight: "bold",
        };
  }
};

const arrowStyle = {
  display: "none", // Change to display: "flex" to show arrows
  color: "white",
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  fontSize: "20px",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
};

export default HomeBannerNew;
