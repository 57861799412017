import React from "react";
import { toast } from "react-toastify"; 

function PrivateRoutesLogin({ children }) {
  const user = JSON.parse(localStorage.getItem("userInformation"));
  console.log(user, "6")
  toast.warning("you need to login or signup before place order")
  const redirectUrl = "/signup";

  if (!user) {
    window.location.href = redirectUrl;
  }
  if (!user) return null;
  return <>{children}</>;
}

export default PrivateRoutesLogin;
