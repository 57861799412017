import React from "react";
import { useSelector } from "react-redux";

const Table = () => {
  const { product, status, error } = useSelector(
    (state) => state.singleProduct
  );

  const productDetails = {
    description: product.description,
    review: "No Reviews",
    shipping: [
      "Free shipping PAN India",
      "7 days easy return policy",
      "6 month warranty",
      "Shipping internationally to 20+ countries",
      "Brand owned and marketed by: Gustyglow",
      "134-135, Second Floor, Sundar Apartment Parshvanath Colony, Nirman Nagar, Jaipur,Rajasthan, Pincode - 302019",
    ],
  };

  return (
    <div>
      <div className="flex justify-center py-8 pt-0 font-poppins mx-2 my-12">
        <div className="border w-full max-w-screen-xl">
          {/* Row 1: Description */}
          <div className="grid grid-cols-1 md:grid-cols-[200px_minmax(300px,_1fr)] p-4 border-b">
            <div className="border-r border-full font-bold text-gray-800 px-4 py-4 text-center md:text-left flex items-center">
              Description
            </div>
            <div className="text-gray-600 px-4 py-4 flex items-center">
              {productDetails && productDetails.description}
            </div>
          </div>

          {/* Row 2: Review */}
          {/* <div className="grid grid-cols-1 md:grid-cols-[200px_minmax(300px,_1fr)] p-4 border-b">
            <div className="border-r font-bold text-gray-800 px-4 py-4 text-center md:text-left flex items-center">
              Review
            </div>
            <div className="text-gray-600 px-4 py-4 flex flex-col md:flex-row items-start md:items-center">
              {productDetails.review}
              <button
                className="ml-0 mt-4 md:ml-4 md:mt-0 py-3 px-8 bg-black text-white rounded-full"
                style={{ backgroundColor: "black", color: "white" }}
              >
                Write a Review
              </button>
            </div>
          </div> */}

          {/* Row 3: Shipping */}
          <div className="grid grid-cols-1 md:grid-cols-[200px_minmax(300px,_1fr)] p-4">
            <div className="border-r font-bold text-gray-800 px-4 py-4 text-center md:text-left flex items-center">
              Shipping
            </div>
            <div className="text-gray-600 px-4 py-4 whitespace-pre-line flex items-start">
              <ul className="list-disc pl-5">
                {productDetails.shipping.map((item, index) => (
                  <li key={index} className="mb-1">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
