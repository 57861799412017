import React, { useState } from "react";
import {
  FaBars,
  FaTimes,
  FaRegHeart,
  FaRegUser,
  FaSearch,
  FaAngleDown,
  FaAngleUp,
} from "react-icons/fa";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const HeaderTop = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [mensDropdownOpen, setMensDropdownOpen] = useState(false);
  const [womensDropdownOpen, setWomensDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const { cart } = useSelector((state) => state.products);
  const { wishlist } = useSelector((state) => state.wishlist);
  const userInformation = JSON.parse(localStorage.getItem("userInformation"));

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleMensDropdown = () => {
    setMensDropdownOpen(!mensDropdownOpen);
  };

  const toggleWomensDropdown = () => {
    setWomensDropdownOpen(!womensDropdownOpen);
  };

  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    navigate(`/search?query=${searchQuery}`);
  };

  const handleLogout = () => {
    localStorage.removeItem("userInformation");
    navigate("/login");
  };

  return (
    <div className="font-poppins">
      <header className="flex items-center justify-between px-4 bg-gray-800 text-white w-full lg:border-b-0 md:border-b-0 border-b">
        <div className="flex items-center justify-between w-full max-w-screen-xl mx-auto">
          {/* Logo */}
          <div className="flex-shrink-0">
            <a href="/">
              <img
                src="/assets/logo/logo4-edited.png"
                alt="Logo"
                className="lg:h-40 h-24 py-2"
              />
            </a>
          </div>

          {/* Search Bar */}
          <div className="flex-1 mx-4 hidden sm:flex justify-center w-full max-w-md">
            <form onSubmit={handleSearchSubmit} className="relative w-full">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search here"
                className="w-full p-4 pl-4 pr-10 rounded-full text-black focus:outline-none"
                style={{ backgroundColor: "#F4E5C8", color: "black" }}
              />
              <button
                type="submit"
                className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400"
              >
                <FaSearch />
              </button>
            </form>
          </div>

          {/* Icons */}
          <div className="flex items-center space-x-8 hidden sm:flex">
            <a href="/wishlist">
              <div className="relative">
                <FaRegHeart
                  className="text-2xl cursor-pointer hover:text-gray-400"
                  size={36}
                />
                {wishlist.length > 0 && (
                  <span
                    className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2  rounded-full px-2 py-1 text-xs"
                    style={{ backgroundColor: "#5f202b", color: "white" }}
                  >
                    {wishlist.length}
                  </span>
                )}
              </div>
            </a>
            <a href="/cart">
              <div className="relative">
                <AiOutlineShoppingCart
                  className="text-2xl cursor-pointer hover:text-gray-400"
                  size={40}
                />
                {cart.length > 0 && (
                  <span
                    className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2  rounded-full px-2 py-1 text-xs"
                    style={{ backgroundColor: "#5f202b", color: "white" }}
                  >
                    {cart.length}
                  </span>
                )}
              </div>
            </a>
            {userInformation ? (
              <div className="relative">
                {userInformation.picture ? (
                  <img
                    src={userInformation.picture}
                    alt="User"
                    className="h-10 w-10 rounded-full cursor-pointer"
                    onClick={toggleUserDropdown}
                  />
                ) : (
                  <div
                    className="h-10 w-10 rounded-full cursor-pointer flex items-center justify-center bg-gray-400 text-white"
                    onClick={toggleUserDropdown}
                    style={{ backgroundColor: "#eac375" }}
                  >
                    <span className="text-xl">
                      {userInformation.name &&
                        userInformation.name.charAt(0).toUpperCase()}
                    </span>
                  </div>
                )}
                {userDropdownOpen && (
                  <div
                    className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-10"
                    style={{ backgroundColor: "white" }}
                  >
                    <a
                      href="/profile"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      Account
                    </a>
                    <a
                      href="/myorders"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      My orders
                    </a>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <a href="/login">
                <FaRegUser
                  className="text-2xl cursor-pointer hover:text-gray-400"
                  size={36}
                />
              </a>
            )}
          </div>

          {/* Hamburger Icon */}
          <div className="flex items-center sm:hidden space-x-4">
            <a href="/wishlist">
              <div className="relative">
                <FaRegHeart
                  className="text-2xl cursor-pointer hover:text-gray-400"
                  size={36}
                />
                {wishlist.length > 0 && (
                  <span
                    className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 rounded-full px-2 py-1 text-xs"
                    style={{ backgroundColor: "#5f202b", color: "white" }}
                  >
                    {wishlist.length}
                  </span>
                )}
              </div>
            </a>
            <a href="/cart">
              <div className="relative">
                <AiOutlineShoppingCart
                  className="text-2xl cursor-pointer hover:text-gray-400"
                  size={36}
                />
                {cart.length > 0 && (
                  <span
                    className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 rounded-full px-2 py-1 text-xs"
                    style={{ backgroundColor: "#5f202b", color: "white" }}
                  >
                    {cart.length}
                  </span>
                )}
              </div>
            </a>
            <button
              className="text-2xl focus:outline-none"
              onClick={toggleMenu}
            >
              {menuOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>
        </div>
      </header>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="bg-gray-800 text-white p-4 sm:hidden">
          <div className="flex justify-between items-center mb-4">
            <form
              onSubmit={handleSearchSubmit}
              className="relative flex-1 mr-4"
            >
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search here"
                className="w-full p-2 pl-4 pr-8 rounded-full text-black focus:outline-none"
                style={{ backgroundColor: "#F4E5C8", color: "black" }}
              />
              <button
                type="submit"
                className="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400"
              >
                <FaSearch />
              </button>
            </form>
            <div className="flex items-center space-x-2">
              {userInformation ? (
                <div className="relative">
                  {userInformation.picture ? (
                    <img
                      src={userInformation.picture}
                      alt="User"
                      className="h-10 w-10 rounded-full cursor-pointer"
                      onClick={toggleUserDropdown}
                    />
                  ) : (
                    <div
                      className="h-10 w-10 rounded-full cursor-pointer flex items-center justify-center bg-gray-400 text-white"
                      onClick={toggleUserDropdown}
                      style={{ backgroundColor: "#e9c771" }}
                    >
                      <span className="text-xl">
                        {userInformation.name &&
                          userInformation.name.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  )}
                  {userDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-100">
                      <a
                        href="/profile"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        Account
                      </a>
                      <a
                        href="/myorders"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        My orders
                      </a>
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <a href="/login">
                  <FaRegUser
                    className="text-2xl cursor-pointer hover:text-gray-400"
                    size={35}
                  />
                </a>
              )}
            </div>
          </div>

          <nav>
            <ul className="space-y-2">
              <li className="mb-2">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={toggleMensDropdown}
                >
                  <span>Men</span>
                  <span className="ml-2">
                    {mensDropdownOpen ? <FaAngleUp /> : <FaAngleDown />}
                  </span>
                </div>
                {mensDropdownOpen && (
                  <ul className="mt-2 space-y-2 pl-4">
                    <li className="mb-2">
                      <a href="/men-chains" className="hover:text-gray-400">
                        Chains
                      </a>
                    </li>
                    <li className="mb-2">
                      <a href="/men-bracelets" className="hover:text-gray-400">
                        Bracelets
                      </a>
                    </li>
                  </ul>
                )}
              </li>
              <li className="mb-2">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={toggleWomensDropdown}
                >
                  <span>Women</span>
                  <span className="ml-2">
                    {womensDropdownOpen ? <FaAngleUp /> : <FaAngleDown />}
                  </span>
                </div>
                {womensDropdownOpen && (
                  <ul className="mt-2 space-y-2 pl-4">
                    <li className="mb-2">
                      <a href="/rings" className="hover:text-gray-400">
                        Rings
                      </a>
                    </li>
                    <li className="mb-2">
                      <a href="/anklets" className="hover:text-gray-400">
                        Anklets
                      </a>
                    </li>
                    <li className="mb-2">
                      <a href="/bracelets" className="hover:text-gray-400">
                        Bracelets
                      </a>
                    </li>
                  </ul>
                )}
              </li>
              {/* 2nd drop */}

              <li className="mb-2">
                <a href="/bracelets" className="hover:text-gray-400">
                  Bracelets
                </a>
              </li>
              <li className="mb-2">
                <a href="/earrings" className="hover:text-gray-400">
                  Earrings
                </a>
              </li>
              <li className="mb-2">
                <a href="/anklets" className="hover:text-gray-400">
                  Anklets
                </a>
              </li>
              <li className="mb-2">
                <a href="/rings" className="hover:text-gray-400">
                  Rings
                </a>
              </li>
              <li className="mb-2">
                <a href="/necklace" className="hover:text-gray-400">
                  Necklace
                </a>
              </li>
              <li className="mb-2" style={{ marginTop: "20px" }}>
                <a
                  href="/bulkorder"
                  className="hover:text-gray-400 py-2 px-4 rounded-lg text-[white] text-[16px]"
                  style={{ backgroundColor: "#5c2028" }}
                >
                  Bulkorder
                </a>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default HeaderTop;
