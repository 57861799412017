import React, { useState, useEffect } from "react";
import axios from "axios";
import TableComponent from "./TableComponent";
import ModalComponent from "./ModalComponent";
import Spinner from "../Spinner";
import { BASE_URL } from "../../constants/url";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import Modal from "react-modal";

// Make sure this is set up correctly in your app
Modal.setAppElement("#root");

const Products = () => {
  const [products, setProducts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [search, setSearch] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);

  // Fetch all products
  const getAllProducts = async () => {
    setLoading(true);
    try {
      // Make the API call to fetch products
      const response = await axios.get(`${BASE_URL}/products/admin`);

      // Check if the response has data
      if (response && response.data) {
        if (response.data.length) {
          setProducts(response.data); // Set products if data exists
        } else {
          toast.error("Product Not Found");
        }
      } else {
        toast.error("Invalid API Response");
      }
    } catch (error) {
      console.error("Error fetching products:", error);

      // Handle specific error scenarios
      if (error.response) {
        // Server responded with a status code out of 2xx range
        toast.error(error.response.data?.message || "Error fetching products.");
      } else if (error.request) {
        // Request was made, but no response was received
        toast.error("No response from server. Please try again later.");
      } else {
        // Something happened setting up the request
        toast.error("An unexpected error occurred.");
      }
    } finally {
      setLoading(false); // Ensure loading is set to false
    }
  };

  useEffect(() => {
    getAllProducts(); // Fetch products on component mount
  }, [isDeleted]);

  // Define columns for the table
  const columns = [
    { Header: "Product ID", accessor: "_id" },
    {
      Header: "Image",
      accessor: "image",
      Cell: ({ cell: { value } }) => (
        <img src={value} alt="Product" className="h-16 w-16 object-cover" />
      ),
    },
    { Header: "Product Name", accessor: "name" },
    { Header: "Qty", accessor: "countInStock" },
    { Header: "Price", accessor: "discountPrice" },
    { Header: "Product Code", accessor: "productCode" },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div>
          <button
            className="bg-[green] text-[white] px-2 py-1 mr-2 rounded"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-[red] text-[white] px-2 py-1 rounded"
            onClick={() => handleDelete(row.original._id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  // Handle product edit
  const handleEdit = (product) => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  // Handle product deletion
  const handleDelete = async (id) => {
    const verify = window.confirm(
      "Are you sure you want to delete the product?"
    );
    if (verify) {
      setLoading(true);
      try {
        const response = await axios.delete(`${BASE_URL}/products/${id}`);
        if (response.status === 200) {
          toast.success("Product deleted successfully");
          getAllProducts(); // Refresh product list
        } else {
          toast.error("Something went wrong while deleting the product");
        }
      } catch (error) {
        toast.error("Something went wrong while deleting the product");
      } finally {
        setLoading(false);
      }
    }
  };

  // Handle modal close
  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  // Handle file change for bulk upload
  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  // Handle bulk upload
  const handleBulkUpload = async () => {
    if (csvFile) {
      const formData = new FormData();
      formData.append("file", csvFile);

      setLoading(true);
      try {
        const response = await axios.post(
          `${BASE_URL}/products/bulk-upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          toast.success("Products uploaded successfully");
          getAllProducts(); // Refresh product list
        } else {
          toast.error("Unexpected response from the server");
        }
      } catch (error) {
        console.error("Error during bulk upload:", error);

        // Handle different error scenarios
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message); // Use the error message from the server
        } else if (error.response) {
          toast.error(`Server responded with status ${error.response.status}`);
        } else if (error.request) {
          toast.error(
            "No response received from the server. Please try again."
          );
        } else {
          toast.error("An unexpected error occurred.");
        }
      } finally {
        setLoading(false);
        setCsvFile(null); // Reset file input
        setBulkUploadModalOpen(false); // Close the bulk upload modal
      }
    } else {
      toast.error("Please select a CSV file to upload");
    }
  };

  // Filter products based on search query (including Product ID)
  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(search.toLowerCase()) ||
      product._id.toLowerCase().includes(search.toLowerCase()) ||
      product.productCode.toLowerCase().includes(search.toLowerCase())
  );

  const handleBulkDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete all products? This action cannot be undone!"
    );

    if (confirmDelete) {
      try {
        // Call backend to delete all products
        const response = await axios.delete(BASE_URL + "/products/bulk-delete");

        // Handle success
        toast.success("All products deleted successfully!", {
          position: "top-right",
          autoClose: 3000, // This matches the toast duration
        });

        setIsDeleted(true);
      } catch (error) {
        // Handle error
        console.error("Error deleting products:", error);
        toast.error("Failed to delete products. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    }
  };

  // Utility function to add a delay

  return (
    <div className="ml-[280px]">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl">Products</h1>
        <div>
          <CSVLink
            data={products}
            filename={"products.csv"}
            className="bg-[#5f202b] text-[white] px-4 py-2 rounded mr-2"
          >
            Download CSV
          </CSVLink>
          <button
            className="bg-[#5f202b] text-[white] px-4 py-2 rounded"
            onClick={() => setModalOpen(true)}
          >
            Add Product
          </button>
          <button
            className="bg-[#5f202b] text-[white] px-4 py-2 rounded ml-2"
            onClick={() => setBulkUploadModalOpen(true)}
          >
            Bulk Upload
          </button>
          <button
            className="bg-[#5f202b] text-[white] px-4 py-2 rounded ml-2"
            onClick={handleBulkDelete}
          >
            Bulk Delete
          </button>
        </div>
      </div>
      <input
        type="text"
        placeholder="Search by Product Name or ID"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="mb-4 px-4 py-2 border border-gray-300 rounded w-full"
      />
      {loading ? (
        <Spinner />
      ) : (
        <TableComponent
          columns={columns}
          data={filteredProducts && filteredProducts}
        />
      )}
      {modalOpen && (
        <ModalComponent
          isOpen={modalOpen}
          onRequestClose={handleModalClose}
          product={selectedProduct}
          onProductUpdated={getAllProducts}
        />
      )}
      {/* Bulk Upload Modal */}
      {bulkUploadModalOpen && (
        <Modal
          isOpen={bulkUploadModalOpen}
          onRequestClose={() => setBulkUploadModalOpen(false)}
          className="relative max-w-lg mx-auto my-10 p-6 bg-white border border-gray-300 rounded shadow-lg"
          overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-50"
        >
          <button
            onClick={() => setBulkUploadModalOpen(false)}
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="max-h-[70vh] overflow-y-auto p-4 bg-[white]">
            <h2 className="text-xl mb-4">Bulk Upload Products</h2>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="form-input mb-4"
            />
            <div className="flex justify-end">
              <button
                onClick={() => setBulkUploadModalOpen(false)}
                className="bg-[red] text-[white] px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleBulkUpload}
                className="bg-[green] text-[white] px-4 py-2 rounded"
              >
                Upload
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Products;
